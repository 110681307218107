import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import Axios from "axios";
import CategoryCard from "../component/CategoryCard/CategoryCard";
import { apiUrl } from "../Constants";
import {
  getCategories,
  fetchAddCategory,
  copyCategoryFromCurrent,
} from "../api/methods";
import { addText, T } from "../language/ru";
import CreateCategoryBlock from "../component/CategoriesList/CreateCategoryBlock";
import { Input } from "../ui-kit/Input/Input";
import Loader from "../component/UI-kit/Loader/Loader";
import { Button } from "../ui-kit/Button/Button";
import { ListGroup } from "react-bootstrap";
import { searchCategories } from "../api/methods";
import debounce from "../commonFunctions/debounce";
import Error from "../component/UI-kit/Error/Error";
import {
  complectCardsLinksWithoutScenarioId,
  complectCardsLinksWithScenarioId,
} from "../Constants";
import CategoryCardLink from "../component/CategoryCard/CategoryCardLink";
const NewCategory = ({ onSubmit }) => {
  const [name, setName] = useState("");
  return (
    <div
      className="category-card "
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h3 className="category-padding">{"Добавление категории"}</h3>
      <div className="category-padding">
        <input
          value={name}
          placeholder="Введите название категории"
          onChange={(e) => setName(e.target.value)}
        ></input>

        <div
          className="btn"
          style={{ marginTop: 10, width: "100%" }}
          onClick={() => {
            if (name !== "" && name) {
              setName("");
              onSubmit(name);
            }
          }}
        >
          Добавить
        </div>
      </div>
    </div>
  );
};

class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.searchField = React.createRef();
    this.showResults = false;
  }
  state = {
    searchCategories: [],
    searchTotalCount: 0,
    searchPageCount: 0,
    categories: [],
    newCategoryName: "",
    userType: localStorage.getItem("type"),
    findCategory: "",
    Count: 50,
    Offset: 0,
    TotalCount: 0,
    errorMessage: "",
    pageCount: 0,
    loading: true,
    resultsShowed: false,
    error: false,
  };

  componentDidMount() {
    this.getCategories(0);
    this.debounceSearchCategories = debounce(searchCategories);
    document.addEventListener("click", (event) => {
      if (event.target !== this.searchField.current) {
        this.setState({ showResults: false });
      } else {
        this.setState({ showResults: true });
      }
    });
  }

  successLoadCategories = (response) => {
    console.log(response);
    if (response.status == 200) {
      const totalCount = response.data.totalCount;
      const pageCount = Math.ceil(
        response.data.totalCount / response.data.count
      );
      this.setState({
        categories: response.data.categories.reverse(),

        totalCount,
        pageCount,
      });
    }
    this.setState({ loading: false });
  };
  getCategories(offset) {
    this.setState({ loading: true });
    getCategories({
      body: { Count: this.state.Count, Offset: offset },
      success: this.successLoadCategories,
      error: this.errorGetCategories,
    });
  }

  handlePageChange = async (e) => {
    const offset = e.selected * this.state.Count;
    this.setState({ Offset: offset });
    console.log(!!this.state.findCategory, this.state.resultsShowed);
    if (this.state.findCategory && this.state.resultsShowed) {
      console.log(false);
      this.debounceSearchCategories({
        body: {
          Count: this.state.Count,
          Offset: offset,
          searchText: this.state.findCategory,
        },
        success: this.successLoadCategories,
        error: this.errorGetCategories,
      });
    } else {
      this.getCategories(offset);
    }
  };

  addCategory = async (category) => {
    fetchAddCategory({ category }, this.successAddCategory);
  };

  successAddCategory = (res) => {
    if (res.status == 201) {
      this.setState({ newCategoryName: "" });
      this.getCategories();
    }
  };
  errorGetCategories = (e) => {
    this.setState({ error: true });
    this.setState({ errorMessage: e });
  };
  successSearchCategories = (response) => {
    if (response.status === 200) {
      console.log(response.data);
      const searchTotalCount = response.data.totalCount;
      const searchPageCount = Math.ceil(
        response.data.totalCount / response.data.count
      );
      this.setState({
        searchCategories: response.data.categories,
        searchTotalCount: response.data.totalCount,
        searchTotalCount,
        searchPageCount,
      });
    }
    this.setState({ loading: false });
  };
  errorSeachCategories = (e) => {
    if (this.state.findCategory) {
      this.setState({ error: true });

      this.setState({ errorMessage: e });
    }
    this.setState({ loading: false });
  };
  copyCategory = (currentId, copiedId, success = () => {}) => {
    copyCategoryFromCurrent(
      { target_id: currentId, copied_id: copiedId },
      () => {
        this.getCategories();
        success();
      }
    );
  };

  deleteCategory(categoryId, Title) {
    if (
      window.confirm(
        'Вы действительно хотите удалить комплект "' + Title + '"?'
      )
    ) {
      const { Refresh_token, Access_token } =
        this.props.token || JSON.parse(localStorage.getItem("token"));
      let data = JSON.stringify({ categoryId });
      Axios({
        url: apiUrl + "?class=Category&action=deleteCategory",
        method: "DELETE",
        data,
        headers: {
          Authorization: Access_token,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.getCategories();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  deleteScenario = (id) => {
    const { Refresh_token, Access_token } = JSON.parse(
      localStorage.getItem("token")
    );
    let data = JSON.stringify({
      scenarioId: id,
    });
    Axios({
      method: "delete",
      url: apiUrl + "?class=Scenario&action=deleteScenario",
      data,
      headers: {
        authorization: Access_token,
      },
    }).then((res) => {
      this.getCategories();
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      loading: true,
    });
    if (!value) {
      this.getCategories(0);
    } else {
      this.debounceSearchCategories({
        body: {
          searchText: this.state.findCategory,
          offset: 0,
          count: 50,
        },
        success: this.successSearchCategories,
        error: this.errorSeachCategories,
      });
    }
  };

  handleModalClose = () => {
    this.setState({ error: false });
    this.setState({ loading: false });
  };

  showSearchCategories = () => {
    if (this.state.findCategory) {
      this.setState({
        categories: this.state.searchCategories,
        totalCount: this.state.searchTotalCount,
        pageCount: this.state.searchPageCount,
        resultsShowed: true,
      });
    }
  };
  render() {
    const { categories, findCategory, error } = this.state;
    const listGroupClass =
      "categories__search-results" + (!findCategory ? " disabled" : "");

    return (
      <div className="categories">
        <Error
          message={this.state.errorMessage}
          handler={this.handleModalClose}
          error={this.state.error}
        />

        <CreateCategoryBlock addCategory={this.addCategory} />
        <div
          className="d-flex flex-row align-items-center categories__search justify-content-between
            "
        >
          <div className="categories__search-wrapper">
            <Input
              onChange={this.handleChange}
              name="findCategory"
              value={findCategory}
              placeholder="Поиск по названию.."
              ref={this.searchField}
            />
            {this.state.showResults && (
              <ListGroup className={listGroupClass}>
                {!this.state.loading && this.state.searchCategories.length
                  ? this.state.searchCategories
                      .slice(0, 15)
                      .map(({ Title, scenarioId, Id }, index) => {
                        const linkInfo = (
                          scenarioId === null
                            ? complectCardsLinksWithoutScenarioId
                            : complectCardsLinksWithScenarioId
                        )[0];

                        // Если нет сценария надо вернуть только линк с false
                        return (
                          <CategoryCardLink
                            key={index}
                            linkInfo={linkInfo}
                            Id={Id}
                            scenarioId={scenarioId}
                          >
                            <ListGroup.Item>{Title}</ListGroup.Item>
                          </CategoryCardLink>
                        );
                      })
                  : null}

                {!this.state.loading && !this.state.searchCategories.length && (
                  <p>ничего не найдено</p>
                )}
                {this.state.loading && <Loader className="center" />}
              </ListGroup>
            )}
          </div>
          <div className={this.state.loading ? " paginate-disabled" : null}>
            <Button
              style={{ marginRight: 0 }}
              onClick={this.showSearchCategories}
            >
              поиск
            </Button>
          </div>
        </div>

        <div className={this.state.loading ? "transparent" : ""}>
          <ReactPaginate
            onPageChange={this.handlePageChange}
            onClick={() => !this.state.loading}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={this.state.pageCount}
            previousLabel="<"
            nextLabel=">"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            disabledClassName="paginate-disabled"
            disabledLinkClassName="paginate-disabled"
            renderOnZeroPageCount={null}
          />
        </div>
        <div
          className={`d-flex flex-column align-items-center w-100
            ${this.state.loading ? "transparent" : ""}`}
        >
          {categories.length == 0 ? (
            <div className="gray mt-3">Комплектов не найдено</div>
          ) : (
            categories.map((category, index) => {
              const { Id, Title, Translated, scenarioId } = category;

              return (
                <CategoryCard
                  copyCategory={(copiedId, success) =>
                    this.copyCategory(Id, copiedId, success)
                  }
                  key={Id}
                  categories={categories}
                  Id={Id}
                  loading={this.state.loading}
                  scenarioId={scenarioId}
                  Title={Title}
                  deleteScenario={() => this.deleteScenario(scenarioId)}
                  deleteCategory={() => this.deleteCategory(Id, Title)}
                  Translated={Translated}
                ></CategoryCard>
              );
            })
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.token || "",
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearError: () => dispatch({ type: "CLEAR_ERROR" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
