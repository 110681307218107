import React, { Fragment, Component, Suspense } from "react";

import "./App.css";
import LoginScreen from "./container/LoginScreen";
import { BrowserRouter, Route, Switch, Link, Redirect } from "react-router-dom";
import Categories from "./container/Categories";

import Error from "./container/Error";
import UnauthenticatedRoute from "./container/UnauthenticatedRoute";

import { connect } from "react-redux";
import AuthenticatedRoute from "./container/AuthenticatedRoute";
import ScriptScreen from "./container/ScriptScreen";
import TestWebSite from "./container/TestWebSite";

import Axios from "axios";
import MainScreen from "./container/MainScreen";
import QsForCheck from "./container/QsForCheck";
import OprosnikAdmin from "./container/OprosnikAdmin";
import {
  links,
  isValid,
  adminPathPropb,
  H,
  isPropb,
  apiUrl,
} from "./Constants";
import UsersScreen from "./container/UsersScreen";
import { getAnswers, generateAccount, getUsers } from "./api/methods";
import LayoutForRouting from "./component/Navigation/LayoutForRouting";
import { checkLoginPropPB } from "./commonFunctions/functions";
import { Promocodes } from "./container/Promocodes";

// import Oprosnik from "./container/Oprosnik";
const Oprosnik = React.lazy(() => import("./container/Oprosnik"));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: "3",
    };
  }

  getTemplate = (id) => {
    const { pathname } = document.location;
    let token = JSON.parse(localStorage.getItem("token"));

    if (isValid(token)) {
      let { Refresh_token, Access_token } = token;
    } else {
      if (
        (pathname !== H.login &&
          !pathname.includes(H.oprosnik) &&
          !pathname.includes("questionarie/")) ||
        pathname.includes("oprosnikadmin")
      ) {
        this.props.signOut("");
        document.location.replace(H.login);
      }
    }

    getUsers({
      error: (err) => {
        let isUnauthenticated = err.toString().includes("401");
        let oprosnikId = pathname.split("/").pop();

        if (isValid(oprosnikId)) {
          // console.log("Отвалился на опроснике");

          return;
        }

        if (isUnauthenticated && pathname !== H.login) {
          this.props.signOut("");
          document.location.replace(H.login);
        }
      },
    });
  };
  componentDidMount() {
    const isOprosnik = document.location.pathname.includes("oprosnik/");
    isOprosnik && isPropb ? checkLoginPropPB() : this.getTemplate(1);
    // if (isOprosnik) {
    //   isPropb ? checkLoginPropPB() : this.getTemplate(1)
    // } else {
    //   this.getTemplate(1);
    // }

    let type = localStorage.getItem("type");
    this.setState({ userType: type });
  }
  checkScreenForUser = () => {
    const { pathname } = document.location;
    const isUser = pathname.includes("oprosnik/");
    this.setState({ isUser });
  };

  render() {
    const { userType } = this.state;
    const isAdmin = userType == "1";
    const value = JSON.parse(localStorage.getItem("token"));
    return (
      <Fragment>
        <BrowserRouter>
          <Switch>
            <UnauthenticatedRoute
              exact
              path={H.login}
              component={LoginScreen}
              appProps={{ isAuthenticated: value === "" ? false : true }}
            />

            <AuthenticatedRoute
              exact
              path={H.categories}
              component={() => (
                <LayoutForRouting>
                  <Categories />
                </LayoutForRouting>
              )}
              appProps={{ isAuthenticated: value === "" ? false : true }}
            />
            <AuthenticatedRoute
              exact
              path={H.main}
              component={() => (
                <LayoutForRouting>
                  <Redirect to={H.categories} />
                </LayoutForRouting>
              )}
              appProps={{ isAuthenticated: value === "" ? false : true }}
            />

            <AuthenticatedRoute
              exact
              path={H.questionaries}
              component={() => (
                <LayoutForRouting>
                  <QsForCheck />
                </LayoutForRouting>
              )}
              appProps={{ isAuthenticated: value === "" ? false : true }}
            />

            <AuthenticatedRoute
              exact
              path={H.scriptScreen}
              component={() => (
                <LayoutForRouting>
                  <ScriptScreen />
                </LayoutForRouting>
              )}
              appProps={{ isAuthenticated: value === "" ? false : true }}
            />
            <AuthenticatedRoute
              exact
              path={H.oprosnik}
              component={() => (
                <LayoutForRouting>
                  <TestWebSite />
                </LayoutForRouting>
              )}
              appProps={{
                isAuthenticated: value === "" ? false : true,
              }}
            />
            <AuthenticatedRoute
              exact
              path={H.oprosnikExact}
              component={() => (
                <Suspense fallback={<div></div>}>
                  <Oprosnik />
                </Suspense>
              )}
              appProps={{ isAuthenticated: value === "" ? true : true }}
            />
            <AuthenticatedRoute
              exact
              path={H.questionnaire}
              component={OprosnikAdmin}
              appProps={{ isAuthenticated: true }}
            />
            <AuthenticatedRoute
              exact
              path={H.oprosnikAdmin}
              component={(props) => (
                <LayoutForRouting>
                  <OprosnikAdmin {...props} />
                </LayoutForRouting>
              )}
              appProps={{
                isAuthenticated: value === "" ? false : true,
              }}
            />

            <AuthenticatedRoute
              exact
              path={H.users}
              component={() => (
                <LayoutForRouting>
                  <UsersScreen />
                </LayoutForRouting>
              )}
              appProps={{
                isAuthenticated: value === "" ? false : true,
              }}
            />
            <AuthenticatedRoute
              exact
              path={H.promocodes}
              component={() => (
                <LayoutForRouting>
                  <Promocodes />
                </LayoutForRouting>
              )}
              appProps={{
                isAuthenticated: value === "" ? false : true,
              }}
            />
            <Route exact path={"*"} component={Error} />
          </Switch>
        </BrowserRouter>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.token || "",
});

const mapDispatchToProps = (dispatch) => {
  return { signOut: (payload) => dispatch({ type: "LOAD_TOKENS", payload }) };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
