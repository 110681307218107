import React from "react";

import styles from "./Input.module.scss";

export const Input = React.forwardRef(
  (
    { onChange = () => {}, value = "", name = "", placeholder = "", ...props },
    ref
  ) => (
    <input
      ref={ref}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      name={name}
      className={styles.input}
      {...props}
    />
  )
);
