import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import text from "../language/loginScreen";
import { apiUrl, H } from "../Constants";

class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      password: "",
    };
    this.handleChangeLogin = this.handleChangeLogin.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeLogin(event) {
    this.setState({ login: event.target.value });
  }
  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    let formData = JSON.stringify({
      login: this.state.login,
      password: this.state.password,
    });
    let http = Axios.create();

    http
      .post(apiUrl + "?class=User&action=auth", formData)
      .then((res) => {
        console.log(res.data);
        if (res.data.data === "error") {
          this.props.onError("login");
          return;
        }

        window.location.href = H.main;

        //this is not valid code
        //onSuccess reducer doesnt update
        //your state so your component doest rerender
        //This causes you to explicitly call forceUpdate
        //which is not considered as best practise
        this.props.onSuccess(res.data);
        this.props.clearError();
        this.forceUpdate();
      })
      .catch((err) => {
        alert("Неверный логин и/или пароль");
        console.log("loginEventError");
      });
    // Axios({
    //   url: apiUrl + "?class=User&action=auth",
    //   method: "POST",
    //   data: formData,
    // })
  }

  render() {
    return (
      <div className="login-block">
        <p
          className="error"
          style={{
            top: this.props.error === "login" ? -35 : 0,
          }}
        >
          <span>{text.error}</span>
        </p>
        <h1 className="title h3">{text.title}</h1>
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="name" className="inp">
            <input
              value={this.state.login}
              onChange={this.handleChangeLogin}
              type="text"
              id="login"
              placeholder="&nbsp;"
            />
            <span className="label">{text.login}</span>
            <span className="border"></span>
          </label>
          <label htmlFor="name" className="inp">
            <input
              id="password"
              type="password"
              placeholder="&nbsp;"
              value={this.state.password}
              onChange={this.handleChangePassword}
            />
            <span className="label">{text.password}</span>
            <span className="border"></span>
          </label>
          <button
            onClick={() => this.handleSubmit}
            className="enter-button"
            type="submit"
          >
            <a>{text.enter}</a>
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.error,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    onSuccess: (payload) => dispatch({ type: "LOAD_TOKENS", payload }),
    onError: (code) => dispatch({ type: "ERROR", code }),
    clearError: () => dispatch({ type: "CLEAR_ERROR" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
