import Spinner from "react-bootstrap/Spinner";
import React from "react";
function BasicExample() {
  return (
    <Spinner
      animation="border"
      role="status"
      variant="primary"
      className="m-auto"
    >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export default BasicExample;
