import React from 'react'

import { ScenarioCard } from '../component/ScenarioCard/ScenarioCard'

import { deleteScenario, getScenarios } from '../commonFunctions/functions'

import { H } from '../Constants'

class TestWebSite extends React.Component {
	state = {
		scripts: [],
		search: '',
	}

	componentDidMount() {
		this.fetchScenarios()
	}

	fetchScenarios = () => {
		getScenarios(this.successScenariosLoad)
	}

	successScenariosLoad = (scripts = []) => {
		this.setState({
			scripts,
		})
	}

	chooseScript = (Id) => {
		document.location.replace(H.oprosnik + '/' + Id)
	}

	handleDeleteScenario = (id, title) => {
		const confirmMessage = `Вы действительно хотите удалить сценарий ${title}?`

		const isConfirmDelete = window.confirm(confirmMessage)

		if (isConfirmDelete) {
			deleteScenario(id, this.fetchScenarios)
		}
	}

	getFilteredScripts = () => {
		const { scripts } = this.state

		const search = this.state.search.toLowerCase()

		const isEmptySearch = search == ''

		if (isEmptySearch) return scripts

		const predicateIdOrTitle = (script) => {
			const Id = script.Id.toLowerCase()
			const Title = script.Title.toLowerCase()

			const isFindId = Id.includes(search)
			const isFindTitle = Title.includes(search)
			return isFindId || isFindTitle
		}

		const filteredScripts = scripts.filter(predicateIdOrTitle)

		return filteredScripts
	}

	sortScripts = (scripts = []) => {
		const sortPredicate = (b, a) => {
			if (a.Id > b.Id) return 1
			if (a.Id < b.Id) return -1
			return 0
		}
		console.log(scripts)
		const sortedScripts = scripts.sort(sortPredicate)

		return sortedScripts
	}

	handleChange = (e) => {
		const { name, value } = e.target
		this.setState({ [name]: value })
	}

	render() {
		const { search } = this.state
		let scripts = this.getFilteredScripts()
		scripts = this.sortScripts(scripts)

		const isEmptyScripts = scripts.length == 0
		const isEmptySearch = search == ''

		return (
			<div className='TestWebSite'>
				<h1>Опросные листы</h1>
				<div style={{ position: 'relative' }}>
					<input
						className='nice'
						placeholder='Поиск'
						onChange={this.handleChange}
						name='search'
						value={search}
					/>
					<div className='content'>
						{isEmptyScripts ? (
							<div className='not-found'>
								{isEmptySearch ? (
									'Загрузка..'
								) : (
									<span>
										Опросный лист <span className='bold'>{search}</span> не
										найден
									</span>
								)}
							</div>
						) : (
							scripts.map((script, index) => {
								const { Id, Title, CategoryId, uuid } = script
								return (
									<ScenarioCard
										uuid={uuid}
										search={search}
										Id={Id}
										Title={Title}
										CategoryId={CategoryId}
										key={index}
										deleteScenario={() => this.handleDeleteScenario(Id, Title)}
										onClick={() => {
											this.chooseScript(Id)
										}}
									/>
								)
							})
						)}
					</div>
				</div>
			</div>
		)
	}
}

export default TestWebSite
