import React from "react";
import { Link } from "react-router-dom";

const CategoryCardLink = ({
  linkInfo: { getPathname = "", text = "", className = "" },
  Id,
  scenarioId,
  templateTitles,
  ...props
}) => (
  <Link
    className={className}
    to={{
      pathname: getPathname(Id, scenarioId),
      //whats that query need for
      //I have foung no info about query prop in to object
      //https://v5.reactrouter.com/web/api/Link/to-object
      query: {
        templates: templateTitles,
      },
    }}
  >
    {props.children || text}
  </Link>
);

export default CategoryCardLink;
