import Axios from "axios";
import { apiUrl } from "../Constants";

const callfunction = async ({
  method = "get",
  methodClass = "",
  action = "",
  body = {},
  onStart = () => {
    "Отсутствует события начала обработки запроса";
  },
  success = (res) => {
    console.log("Отсутствует метод success");
  },
  error = (err) => {
    console.log("Ошибка", err);
  },
}) => {
  onStart();
  const token = JSON.parse(localStorage.getItem("token"));
  let Access_token = "";
  const headers = {};
  if (token) {
    Access_token = token.Access_token;
  }
  let url = apiUrl + "?class=" + methodClass + "&action=" + action;

  if (Access_token != "") {
    body.JWT = Access_token;
    headers.authorization = Access_token;
  }
  body = JSON.stringify(body);

  let responseType = "json";

  if (action == "buildDocuments" || action == "generateAccount") {
    responseType = "blob";
  }

  Axios({
    method,
    url,
    responseType,
    data: body,
    headers,
  })
    .then(success)
    .catch(error);
};

export async function getAnswers({
  body = {
    Email: null,
    Name: null,
    IsPayed: null,
    TimeStart: null,
    TimeEnd: null,
    Count: null,
    Offset: null,
    TotalCount: null,
  },
  success,
  error,
}) {
  const {
    Email,
    Name,
    IsPayed,
    TimeStart,
    TimeEnd,
    Count,
    Offset,
    TotalCount,
  } = body;
  callfunction({
    method: "post",
    methodClass: "Scenario",
    action: "getAnswers",
    body: {
      email: Email,
      name: Name,
      isPayed: IsPayed,
      timeStart: TimeStart,
      timeEnd: TimeEnd,
      count: Count,
      offset: Offset,
      totalCount: TotalCount,
    },
    success,
    error,
  });
}

export function getQ({ id, success }) {
  callfunction({
    method: "get",
    methodClass: "Scenario",
    action: "getJSON&id=" + id,
    success,
  });
}
export function setPayStatus({ payStatus = null, id = null, success }) {
  callfunction({
    method: "post",
    methodClass: "Scenario",
    action: "setPayStatus",
    success,
    body: { payStatus, id },
  });
}
export function searchCategories({ success, body, error }) {
  const { searchText, count, offset } = body;
  callfunction({
    method: "post",
    methodClass: "Category",
    action: "getCategories",
    body: { searchText, count, offset },
    success,
    error,
  });
}
export function changeTotal({ total = null, id = null, success }) {
  callfunction({
    method: "post",
    methodClass: "Scenario",
    action: "changeTotal",
    success,
    body: { total, id },
  });
}

export function changeAnswer({ json = null, id = null, success, error }) {
  callfunction({
    method: "post",
    methodClass: "Scenario",
    action: "changeAnswer",
    success,
    error,
    body: { json, id },
  });
}
export function saveBitrixAnswer({ json = null, link = null, success, error }) {
  callfunction({
    method: "post",
    methodClass: "Market",
    action: "saveQuestionnaire",
    success,
    error,
    body: { json, link },
  });
}

export function completeBitrixAnswer({ link = null, success, error }) {
  callfunction({
    method: "post",
    methodClass: "Market",
    action: "competeQuestionnaire",
    success,
    error,
    body: { link },
  });
}

export function deleteAnswer({ id = null, success }) {
  callfunction({
    method: "post",
    methodClass: "Scenario",
    action: "deleteAnswer",
    success,
    body: { id },
  });
}
export function generateAccount({ id = null, success, onStart, error }) {
  callfunction({
    method: "post",
    methodClass: "Scenario",
    action: "generateAccount",
    success,
    error,
    onStart,
    body: { id },
  });
}

export function buildDocuments({ id = null, success, onStart }) {
  callfunction({
    method: "post",
    methodClass: "Scenario",
    action: "buildDocuments",
    success,
    onStart,
    body: { id },
  });
}

export function changeComment({ comment = null, id = null, success }) {
  callfunction({
    method: "post",
    methodClass: "Scenario",
    action: "changeComment",
    success,
    body: { comment, id },
  });
}

export function getUsers({ success, error }) {
  callfunction({
    method: "get",
    methodClass: "User",
    action: "getUsers",
    success,
    error,
  });
}

export function deleteUser({ id = null, success }) {
  callfunction({
    method: "post",
    methodClass: "User",
    action: "deleteUser",
    success,
    body: { id },
  });
}

export function addNewUser({
  login = null,
  fio = null,
  password = null,
  status = null,
  success,
}) {
  callfunction({
    method: "post",
    methodClass: "User",
    action: "addNewUser",
    success,
    body: { login, password, status, fio },
  });
}

export function addCategory({ userId = null, categoryId = null, success }) {
  callfunction({
    method: "post",
    methodClass: "User",
    action: "addCategory",
    success,
    body: { userId, categoryId },
  });
}
export function deleteCategory({ userId = null, categoryId = null, success }) {
  callfunction({
    method: "post",
    methodClass: "User",
    action: "deleteCategory",
    success,
    body: { userId, categoryId },
  });
}

export function getCategories({
  body = {
    Count: null,
    Offset: null,
  },
  success,
  error,
}) {
  const { Count, Offset } = body;
  callfunction({
    method: "post",
    methodClass: "Category",
    action: "getCategories",
    body: { count: Count, offset: Offset },
    success,
    error,
  });
}

export function addCategoryForAll({ category = null, success }) {
  callfunction({
    method: "post",
    methodClass: "Category",
    action: "addCategory",
    success,
    body: { category },
  });
}
export function deleteCategoryForAll({ categoryId = null, success }) {
  callfunction({
    method: "delete",
    methodClass: "Category",
    action: "deleteCategory",
    success,
    body: { categoryId },
  });
}

export async function addDocument(categoryId, templateName) {
  if (templateName == "") {
    alert("Сначала введите название шаблона");
    return;
  }
  const { Refresh_token, Access_token } = JSON.parse(
    localStorage.getItem("token")
  );
  let data = JSON.stringify({
    category: categoryId,
    title: templateName,
  });

  return Axios({
    method: "post",
    url: apiUrl + "?class=Template&action=addTemplate",
    data,
    async: false,
    headers: {
      Authorization: Access_token,
    },
  }).then((res) => {
    // alert('Вы добавили документ!')
  });
}
export async function deleteDocument(templateId, success = () => {}) {
  const { Refresh_token, Access_token } = JSON.parse(
    localStorage.getItem("token")
  );
  let data = JSON.stringify({
    templateId,
  });

  return Axios({
    method: "delete",
    url: apiUrl + "?class=Template&action=deleteTemplate",
    data,
    headers: {
      Authorization: Access_token,
    },
  }).then((res) => {
    success();
    console.log("У нас получилось!!");
  });
}

export async function sendMail(
  body = {
    id: null,
    email: null,
  },
  success,
  error
) {
  callfunction({
    method: "post",
    methodClass: "Scenario",
    action: "sendMail",
    body,
    success,
    error,
  });
}

export async function createPromocode(
  body = {
    name: null,
    discount: null,
  },
  success,
  error
) {
  callfunction({
    method: "post",
    methodClass: "Promocode",
    action: "create",
    body,
    success,
    error,
  });
}

export async function getAllPromocodes(success, error) {
  callfunction({
    method: "get",
    methodClass: "Promocode",
    action: "getAll",
    body: {},
    success,
    error,
  });
}

export async function deletePromocode(body = { id: null }, success, error) {
  callfunction({
    method: "delete",
    methodClass: "Promocode",
    action: "delete",
    body,
    success,
    error,
  });
}

export async function findPromocode(body = { name: null }, success, error) {
  callfunction({
    method: "post",
    methodClass: "Promocode",
    action: "find",
    body,
    success,
    error,
  });
}

export async function fetchAddCategory(
  body = { category: null },
  success,
  error
) {
  callfunction({
    method: "post",
    methodClass: "Category",
    action: "addCategory",
    body,
    success,
    error,
  });
}

export async function copyCategoryFromCurrent(
  body = { target_id: null, copied_id: null },
  success,
  error
) {
  callfunction({
    method: "post",
    methodClass: "Category",
    action: "copyCategory",
    body,
    success,
    error,
  });
}
