import React from "react";
import Alert from "react-bootstrap/Alert";
export default function Error({ message, error, handler }) {
  return (
    <Alert
      show={error}
      variant="danger"
      onClose={handler}
      dismissible
      className="position-fixed w-50 bottom-0 start-50 translate-middle-x categories-overlay"
    >
      <Alert.Heading>Уупс... Вы столкнулись с проблемой</Alert.Heading>
      <p>{String(message)}</p>
    </Alert>
  );
}
