import {
  createOprosnikText,
  editOprosnikText,
  fillOpronsikText,
} from "./language/ru";

// Конфиг для ndevelop  ////////////////////////////////////////////////////////////////////////////////
// export const isPropb = false;
// export const apiUrl = "http://ndevelop.ru/api/";
////////////////////////////////////////////////////////////////////////////////////////////////////////

// Обычный конфиг  ////////////////////////////////////////////////////////////////////////////////
export const isPropb = document.location.hostname.includes("propb");
export const propbApiUrl = "https://propb.ru/rdapi/";
// export const gotdocApiUrl = "https://servicedev.gotdoc.ru/api/";  // апи для разработчика
// export const gotdocApiUrl = "https://gotdoc.nord.su/api/";
export const gotdocApiUrl = "https://gotdocnew.nord.su/api";

export const apiUrl = isPropb ? propbApiUrl : gotdocApiUrl;
////////////////////////////////////////////////////////////////////////////////////////////////////////

export const adminPathPropb = isPropb ? "/rdadmin" : "";

export const legalText =
  "Нажимая кнопку «Отправить», я даю свое согласие на обработку моих персональных данных, в соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных», на условиях и для целей, определенных в Согласии на обработку персональных данных";

export const nameForHideStruct = "%%HIDE%%";

//  это не точка с запятой, а специальный греческий символ
export const listDivider = ";";

export const H = {
  main: `${adminPathPropb}/`,
  users: `${adminPathPropb}/users`,
  usersExact: `${adminPathPropb}/users/:id`,
  categories: `${adminPathPropb}/categories`,
  questionaries: `${adminPathPropb}/questionaries`,
  oprosnik: `${adminPathPropb}/oprosnik`,
  oprosnikAdminShort: `${adminPathPropb}/oprosnikadmin/`,
  oprosnikAdmin: `${adminPathPropb}/oprosnikadmin/:id/:scenarioId`,
  oprosnikExact: `${adminPathPropb}/oprosnik/:id`,
  questionnaire: `${adminPathPropb}/questionarie/:uuid`,
  login: `${adminPathPropb}/login`,
  script: `${adminPathPropb}/script`,
  scriptScreen: `${adminPathPropb}/script:id`,
  promocodes: `${adminPathPropb}/promocodes`,
};

export const linksAdmin = [
  {
    href: H.main,
    label: "Главная",
  },

  {
    href: H.users,
    label: "Пользователи",
  },

  {
    href: H.categories,
    label: "Комплекты документов",
  },

  {
    href: H.questionaries,
    label: "Анкеты от пользователей",
  },
  {
    href: H.oprosnik,
    label: "Опросники",
  },
  {
    href: H.promocodes,
    label: "Промокоды",
  },
];
export const linksEditor = [
  {
    href: H.main,
    label: "Главная",
  },

  {
    href: H.categories,
    label: "Комплекты документов",
  },

  {
    href: H.questionaries,
    label: "Анкеты от пользователей",
  },
  {
    href: H.oprosnik,
    label: "Опросники",
  },
];

export const statuses = [
  {
    value: "1",
    label: "Администратор",
  },
  {
    value: "2",
    label: "Редактор",
  },
  // {
  //   value: "3",
  //   label: "Автор",
  // },
];

export const initialStateUser = {
  FIO: "",
  login: "",
  password: "",
};
export const userInfoPlaceHolders = {
  FIO: "ФИО",
  login: "Логин",
  password: "Пароль",
};

export const complectCardsLinksWithoutScenarioId = [
  {
    getPathname: (Id) => `${H.script}:${Id}`,
    className: "",
    text: createOprosnikText,
  },
];
export const complectCardsLinksWithScenarioId = [
  {
    getPathname: (Id, scenarioId) =>
      `${H.script}:${Id}&scenarioId:${scenarioId}`,
    className: "",
    text: editOprosnikText,
  },
  {
    getPathname: (Id, scenarioId) => `${H.oprosnik}/${scenarioId}`,
    className: "ml-3",
    text: fillOpronsikText,
  },
];

export const isValid = (a) => {
  if (typeof a !== "undefined" && a != null && a !== "") {
    return true;
  } else {
    return false;
  }
};

export const maxLengthTemplateName = 100;
